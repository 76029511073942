import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-message',
  standalone: true,
  imports: [],
  templateUrl: './loader-message.component.html',
  styleUrl: './loader-message.component.css'
})
export class LoaderMessageComponent {

}
