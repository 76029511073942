<div class="modal-header">
  <h5 class="modal-title">{{"template.viewTemplate" | translate}}</h5>
  <button type="button" class="close unhighlight" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body">
  <div class="card h-100">
    <div class="card-body">
      <p class="card-title"><span class="font-weight-bold">{{"template.templateId" | translate}}:</span> {{template?.templateId}}</p>
      <p class="card-text"><span class="font-weight-bold">{{"template.version" | translate}}:</span> {{template?.version}}</p>
      <p class="card-text"><span class="font-weight-bold"> {{"template.label" | translate}}:</span> {{template?.label}}</p>
      <p class="card-text"><span class="font-weight-bold">{{"template.rawdata" | translate}}:</span> </p><p>{{template?.rawData}}</p>
      <p class="card-text"><span class="font-weight-bold"> {{"template.description" | translate}}:</span> {{template?.description}}</p>
    </div>
  </div>
</div>

