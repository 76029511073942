<div class="chat-bubble-container">
  <img src="assets/images/Logo.svg" alt="Thumbnail" class="chat-thumbnail">
  <div class="chat-bubble">
    @if (message.rawData) {
      <p>{{ message.rawData }}</p>
      @if ( message.messageStatus == MessageStatus.MESSAGE_FINALIZED || message.messageStatus == MessageStatus.TOOL_EXECUTION_PENDING){
        <p>{{ message.tooling.rawData }} </p>
        <p>{{ 'tools.checkTool' | translate }}: <span><i
          style="cursor: pointer" (click)="jumpToTool(message.userChoice,message.tooling.toolingId)" class="bi mr-2 bi-caret-right-fill"></i></span></p>
      }
    }
    @else {
      <p>{{ 'chat.noRawData' | translate }} </p>
    }
  </div>
</div>
<div class="buttonContainer" *ngFor="let tool of message.availableUserChoices">
  <button class="btn btn-primary mt-2"
          (click)="toolSelector(tool,message.messageId)">{{ 'tools.' + tool | translate }}
  </button>
</div>

