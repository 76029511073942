<div class="chat-bubble-container">
  <div class="chat-bubble">
    <div class="row w-100">
      <div class="col-6">

      </div>
      <div class="col-6 text-right">
        <div class="chatLoader"></div>
      </div>
    </div>
  </div>
</div>
