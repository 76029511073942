import {Component, Input} from '@angular/core';
import {chatMessage, GuardrailDetail} from "../../../models/chatMessage";
import {JsonPipe, KeyValuePipe, NgForOf} from "@angular/common";

@Component({
  selector: 'app-human-message',
  standalone: true,
  imports: [
    JsonPipe,
    KeyValuePipe,
    NgForOf
  ],
  templateUrl: './human-message.component.html',
  styleUrl: './human-message.component.css'
})

export class HumanMessageComponent {
  @Input() message: chatMessage | undefined ;

  trackGuard(index: number, guard: { key: string, value: GuardrailDetail }): string {
    return guard.key;
  }
  getValidationError(guard: { key: string, value: any }): boolean {
    return guard.value && guard.value.error;
  }

  protected readonly GuardrailDetail = GuardrailDetail;
  protected readonly Object = Object;
}
