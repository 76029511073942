<div style="margin:30px"  class="mb-4">
  <div class="row d-flex align-items-stretch">
    <div class="col-md-3 col-12">
      <div class="card h-100">
        <div class="card-body">
          <p class="card-title"><span class="font-weight-bold">{{"document.displayName" | translate}}:</span> {{documentElement?.displayName}}</p>
          <p class="card-text"><span class="font-weight-bold">{{"document.status" | translate}}:</span> {{documentElement?.status}}</p>
          <p class="card-text"><span class="font-weight-bold"> {{'document.size' | translate}}:</span> {{documentElement?.size | toKb}} Kb</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="card h-100">
        <div class="card-body">
          <p class="card-text"><span class="font-weight-bold"> {{'document.description' | translate}}: </span> {{documentElement?.description}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-12">
      <div class="card h-100">
        <div class="card-body d-flex align-items-center justify-content-center">

          <p class="card-text"><button class="btn btn-primary" (click)="openFile(documentElement.documentId)">Open preview</button></p>
        </div>
      </div>
    </div>
  </div>
<div class="tableContainer mt-4">
  <table *ngIf="this.documentPartitions"  datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
    <tr>
      <th>{{"document.partition.id" | translate}}</th>
      <th>{{"document.partition.elementType" | translate}}</th>
      <th>{{"document.partition.version" | translate}}</th>
      <th>{{"document.partition.size" | translate}}</th>
      <th>{{"document.partition.summary" | translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let partitionElement of documentPartitions?.partitions">
      <td class="font-weight-bold">{{ partitionElement.partitionId }}</td>
      <td>{{ partitionElement.elementType }}</td>
      <td>{{ partitionElement.version}}</td>
      <td>{{ partitionElement.size | toKb}} Kb</td>
      <td>{{ partitionElement.semanticSummary | shorten}}</td>

      <td>
        <fa-icon *ngIf="partitionElement.elementType=='text' || partitionElement.elementType=='title'" [icon]="faPenToSquare" (click)="openTextEditDialog(partitionElement)"></fa-icon>
        <fa-icon *ngIf="partitionElement.elementType=='image'" [icon]="faPenToSquare" (click)="openImageEditDialog(partitionElement)"></fa-icon>
        <fa-icon [icon]="faTrash" (click)="delete( partitionElement.partitionId  )"></fa-icon></td>
    </tr>
    </tbody>
  </table>
  </div>
</div>
