<div class="container mt-4">
  <div class="wrapper" >
    <!-- Your content here, can be dynamically added to fill from bottom to up -->
    <div  #chatContainer *ngIf="chatMessages?.messages?.length; else noMessages" class="chat-container" >
        <div *ngFor="let message of chatMessages?.messages" [ngClass]="{'assistant-message': message.messageType === messageTypes.ASSISTANT_MESSAGE, 'human-message': message.messageType === messageTypes.HUMAN_MESSAGE}">
          <app-assistant-message [message]="message" *ngIf="message.messageType==messageTypes.ASSISTANT_MESSAGE" > </app-assistant-message>
          <app-human-message [message]="message" *ngIf="message.messageType==messageTypes.HUMAN_MESSAGE && message.messageId!=''" > </app-human-message>
          <app-tool-selector (functionCallEvent)="callFunction($event)" [message]="message" [chatId]="this.chatId"  *ngIf="message.messageType==messageTypes.TOOL_SELECTOR"> </app-tool-selector>
          <app-redacted-message [message]="message" *ngIf="message.messageType==messageTypes.REDACTED && message.messageId!=''" > </app-redacted-message>
          <app-loader-message *ngIf="message.messageType==messageTypes.PLACEHOLDER" > </app-loader-message>

        </div>

    </div>
    <ng-template #noMessages>
      <p>No messages available.</p>
    </ng-template>
  </div>



  <div class="input-box d-flex">
    <input
      [(ngModel)]="humanMessage"
      [disabled]="!chatId"
      class="form-control"
      placeholder="Type your message here..."
      (keydown.enter)="sendMessage()"
    />
    <button
      class="btn btn-primary ms-2"
      (click)="sendMessage()"
      [disabled]="!humanMessage.trim() || !chatId"
    >Send</button>
  </div>


</div>
