import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatRepository} from "../../repositories/chat.repository";
import {lastValueFrom, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {JsonPipe, NgClass, NgFor, NgIf} from "@angular/common";
import {chatMessages} from "../../models/chatMessages";
import {chat} from "../../models/chatModel";
import {MessageStatus, MessageType} from '../../models/enums';
import {AssistantMessageComponent} from "../chatTypes/assistant-message/assistant-message.component";
import {ToolSelectorComponent} from "../chatTypes/tool-selector/tool-selector.component";
import {HumanMessageComponent} from "../chatTypes/human-message/human-message.component";
import {LoaderMessageComponent} from "../chatTypes/loader-message/loader-message.component";
import {FormsModule} from "@angular/forms";
import {RedactedMessageComponent} from "../chatTypes/redacted-message/redacted-message.component";

@Component({
  selector: 'app-chat-component',
  standalone: true,
  imports: [NgFor, NgIf, AssistantMessageComponent, ToolSelectorComponent, HumanMessageComponent,LoaderMessageComponent,RedactedMessageComponent, NgClass, FormsModule, JsonPipe],
  templateUrl: './chat-component.component.html',
  styleUrls: ['./chat-component.component.css']
})
export class ChatComponentComponent implements OnInit, OnDestroy {
  public messageResponse: any[] = []; // Assuming this is an array of messages
  public chat: chat | null = null;
  chatId: string | null = "";
  message: string = "";
  humanMessage: string = "";
  messageTypes = MessageType;
  isLoading=false;
  private eventSubscription: Subscription | undefined;
  chatMessages: chatMessages | undefined = undefined;
  @ViewChild('chatContainer') private chatContainer!: ElementRef;

  constructor(private chatRepository: ChatRepository, private router: Router, private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('id');
      if (this.chatId) {
        this.loadMessages(this.chatId);
      }
    });
  }

  async loadMessages(id: string): Promise<void> {
    this.isLoading=true;
    this.chatRepository.getMessages(id).subscribe(
      async (chatMessages: chatMessages) => {
        this.chatMessages = chatMessages;
        this.messageResponse = chatMessages.messages;
        await this.checkAndUpdateLastMessage();
        this.scrollToBottom();
        console.log('Received messages:', this.chatMessages);
    if (this.chatMessages.messages[this.chatMessages.messages.length-1].messageStatus ==MessageStatus.MESSAGE_GENERATION_PENDING && this.chatMessages.messages[this.chatMessages.messages.length-1].messageType == MessageType.PLACEHOLDER){
          setTimeout(async () => {
            await this.loadMessages(id);
          }, 1000);
        }
        else{
          this.isLoading=false;
        }
      },
      error => {
        console.error('Error fetching messages:', error);
        this.router.navigate(['/chat']);
      }
    );
  }

  async sendMessage() {
    if (this.chatId) {
      let newMessage = await lastValueFrom(this.chatRepository.newMessage(this.chatId, this.humanMessage));
      await new Promise(resolve => setTimeout(resolve, 200));
      await this.loadMessages(this.chatId);
      this.humanMessage="";
    } else {
      console.log("TODO : auto create");
    }
  }

  async checkAndUpdateLastMessage() {
    const lastMessage = this.messageResponse[this.messageResponse.length - 1];

    if (lastMessage.messageType === MessageType.PLACEHOLDER && lastMessage.messageStatus === 'MESSAGE_GENERATION_PENDING') {
      const messageId = lastMessage.messageId;

      this.chatRepository.getMessage(this.chatId!, messageId).subscribe(
        response => {
          // Update the last element with the response from the server
          if(response.messageId!=""){
            this.messageResponse[this.messageResponse.length - 1] = response;
            this.scrollToBottom();
          }
        },
        error => {
          console.error('Error fetching message:', error);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
  callFunction(event: { functionName: string, args: any[] }) {
    const functionName = event.functionName;
    const args = event.args;

    const functionToCall = (this as any)[functionName];
    if (functionToCall && typeof functionToCall === 'function') {
      functionToCall.apply(this, args);  // Call the function with additional parameters
    } else {
      console.warn(`Function ${functionName} not found on parent component`);
    }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatContainer.nativeElement.scroll({
          top:  this.chatContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
        console.log(this.chatContainer.nativeElement.scrollHeight);
      }, 100);
    } catch(err) {
      console.error('Error in scrollToBottom:', err);
    }
  }

  protected readonly MessageType = MessageType;
  protected readonly MessageStatus = MessageStatus;
}
