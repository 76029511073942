import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../services/notification.service";
import {PromtRepository} from "../../repositories/promt.repository";
import {Router} from "@angular/router";
import {JsonPipe} from "@angular/common";
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";

@Component({
  selector: 'app-view-template',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    JsonPipe,
    ToKbPipe
  ],
  templateUrl: './view-template.component.html',
  styleUrl: './view-template.component.css'
})
export class ViewTemplateComponent {
  uploadForm: FormGroup;
  @Input() template!: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private templateRepository: PromtRepository,
    private router: Router,
  ) {
    this.uploadForm = this.fb.group({
      description: ['', Validators.required],
      label: ['', Validators.required],
      rawData: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.template) {
      this.uploadForm.patchValue({
        description: this.template.description || '',
        rawData: this.template.rawData || '',
        label: this.template.label || '',
      });
    }

  }
  onSubmit(): void {
    const {description} = this.uploadForm.value;
    const {rawData} = this.uploadForm.value;
    const {label} = this.uploadForm.value;
    if (this.uploadForm.valid) {
      if (this.template.templateId) {
        this.templateRepository.patchTemplate(this.template.templateId,description, rawData, label).subscribe(
          (response) => {
            console.log('Upload successful', response);
            this.notificationService.showSuccess("Siker", "template.successAddNew");
            this.activeModal.close(true);
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          },
          (error) => {
            console.error('Upload failed', error);
          }
        );
      } else {
        this.templateRepository.newTemplate(description, rawData, label).subscribe(
          (response) => {
            console.log('Upload successful', response);
            this.notificationService.showSuccess("Siker", "template.successAddNew");
            this.activeModal.close(true);
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          },
          (error) => {
            console.error('Upload failed', error);
          }
        );
      }
    }
  }
  onClose(): void {
    this.activeModal.close();
  }
}
