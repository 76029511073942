import {AvailableUserChoices, MessageStatus, MessageType, ToolingType} from './enums';

export class chatMessage {
  seq: number = 0;
  messageId: string = '';
  tooling: any;
  toolingType: ToolingType = ToolingType.RAG_AGENT;
  messageType: MessageType = MessageType.HUMAN_MESSAGE;
  availableUserChoices: AvailableUserChoices[] = [];
  userChoice: AvailableUserChoices = AvailableUserChoices.NONE;
  messageStatus: MessageStatus = MessageStatus.DEFAULT;
  memorySummary: string = '';
  rawData: string = '';
  attachment: string = '';
  inputGuardrail: InputGuardrail | null = null;

  constructor(data: any) {
    if (data) {
      this.seq = data.seq;
      this.messageId = data.messageId;
      this.tooling = data.tooling;
      this.toolingType = data.toolingType;
      this.messageType = data.messageType;
      this.availableUserChoices = data.availableUserChoices;
      this.userChoice = data.userChoice;
      this.messageStatus = data.messageStatus;
      this.memorySummary = data.memorySummary;
      this.rawData = data.rawData;
      this.attachment = data.attachment;
      if (data.inputGuardrail) {
        this.inputGuardrail = new InputGuardrail(data.inputGuardrail);
      }
    }
  }
}

export class InputGuardrail {
  properties: { [key: string]: GuardrailDetail };

  constructor(guardrailData: { [key: string]: any }) {
    this.properties = {};
    for (const key in guardrailData) {
      if (guardrailData.hasOwnProperty(key)) {
        const detail = guardrailData[key];
        this.properties[key] = new GuardrailDetail(
          detail.call_id,
          detail.raw_llm_output,
          detail.validated_output,
          detail.reask,
          detail.validation_passed,
          detail.error
        );
      }
    }
  }
}

export class GuardrailDetail {
  call_id: string;
  raw_llm_output: string;
  validated_output: string;
  reask: any;
  validation_passed: boolean;
  error: any;

  constructor(call_id: string, raw_llm_output: string, validated_output: string, reask: any, validation_passed: boolean, error: any) {
    this.call_id = call_id;
    this.raw_llm_output = raw_llm_output;
    this.validated_output = validated_output;
    this.reask = reask;
    this.validation_passed = validation_passed;
    this.error = error;
  }
}
