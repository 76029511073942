import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {lastValueFrom, Subject} from "rxjs";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DataTablesModule} from "angular-datatables";
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";
import {TranslateModule} from "@ngx-translate/core";
import {Config} from "datatables.net";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {faEye} from '@fortawesome/free-solid-svg-icons';
import {PromtRepository} from "../../repositories/promt.repository";
import {AddTemplateComponent} from "../../components/add-template/add-template.component";
import {ViewTemplateComponent} from "../../components/view-template/view-template.component";


@Component({
  selector: 'app-promt-page',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, DataTablesModule, ToKbPipe,TranslateModule],
  templateUrl: './promt-page.component.html',
  styleUrl: './promt-page.component.css'
})
export class PromtPageComponent {
  faEye = faEye;

  templateList: any=null;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  private dtElement: any;

  constructor(private templateRepository: PromtRepository,private modalService: NgbModal,private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.templateRepository.getTemplates().subscribe((res: any) => {
      this.templateList = res;
      console.log(this.templateList);
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
  }
  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      console.log("table event")
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  openUploadDialog(template : any = {} ): void {
    const modalRef = this.modalService.open(ViewTemplateComponent, { size: 'lg' });
    modalRef.componentInstance.template = template;
    modalRef.result.then((result) => {
      if (result) {
        console.log('Plugin uploaded successfully');
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }

  async deleteItem(templateId: string) {
    await lastValueFrom(this.templateRepository.deleteItem(templateId));
  }

  showSwal(docId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.deleteItem(docId)
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your template has been deleted.",
          icon: "success"
        }).then(async (result)=> {
          if (result.isConfirmed) {
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          }
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your tempolate is safe :)",
          icon: "error"
        });
      }
    });
  }
}
